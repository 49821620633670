.order-edit {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  .order-left {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    flex-basis: 0;
    padding: 8px;

    .order-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 8px;

      .order-user-header {
        font-size: 24px; }

      .order-user-balance {
        font-size: 24px; } }

    .order-categories {
      display: flex;
      flex-direction: column;

      .order-tabs {
        display: flex;
        flex-direction: row;

        .order-tab {
          font-size: 24px;
          padding-left: 8px;
          padding-right: 8px;
          min-height: 50px;

          &:nth-child(n + 2) {
            border-left: 1px solid black; }

          &.selected {
            background-color: lightblue; } } }

      .order-products {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-top: 1px solid black;
        padding: 8px;

        .order-product {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          min-height: 64px;
          min-width: 128px;
          margin-right: 8px;
          margin-bottom: 8px; } } } }

  .order-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    background-color: #eee;
    padding: 8px;

    .order-list {
      display: flex;
      flex-direction: column;
      flex-grow: 3;
      justify-content: space-between;

      .order-list-item-top {
        display: flex;
        flex-direction: column; }

      .order-list-item-bottom {
        display: flex;
        flex-direction: column; }

      .order-list-item {
        display: flex;
        flex-direction: row;
        font-size: 24px;
        min-height: 64px;

        &.selected {
          font-weight: bold; }

        > * {
          align-items: center; }

        .order-list-item__amount {
          display: flex;
          margin-right: 16px; }

        .order-list-item__label {
          display: flex;
          flex-grow: 1; }

        .order-list-item__cost {
          display: flex; }

        .order-list-item__remove {
          display: flex;
          justify-content: center;
          min-height: 32px;
          min-width: 32px;
          margin-left: 16px; } } }

    .num-pad {
      flex-grow: 1; } } }
