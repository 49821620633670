.screen-keyboard {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  overflow-x: hidden;

  .key-row {
    display: flex;
    flex-direction: row;
    justify-content: center; }

  .char {
    display: flex;
    min-width: 64px;
    min-height: 64px;
    margin: 8px;
    justify-content: center; }

  .space {
    min-height: 64px;
    min-width: 384px;
    margin: 8px; }

  .logo {
    position: absolute;
    bottom: 0;
    right: -476px; } }
