.num-pad {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    flex-grow: 1; }

  .confirm {
    background-color: #006600;
    color: white; }

  .cancel {
    background-color: #8a1f11;
    color: white; }

  @media screen and (max-width: 1300px) {
    .confirm, .cancel {
      font-size: 18px; } } }

.num-pad__button {
  display: flex;
  min-width: 64px;
  min-height: 64px;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  font-size: 32px; }
