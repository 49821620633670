@import "./admin";
@import "./num-pad";
@import "./order";
@import "./screen-keyboard";
@import "./typography";
@import "./users";

html, body {
  width: 100%;
  height: 100%;
  margin: 0;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; } }

.center-column {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  align-self: center; }

*:not(h1, h2, h3, h4, h5, h6) {
  font-size: 20px; }
