.users {
  padding-top: 48px;

  .query-container {
    display: flex;
    flex-shrink: 0;

    .query {
      display: flex;
      flex-grow: 1; }

    .backspace {
      min-height: 64px;
      min-width: 136px;
      margin: 8px; } }

  .user-list {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .user-list-item {
      display: flex;
      flex-direction: row;
      min-height: 64px;
      align-items: center;

      &:nth-child(even) {
        background-color: #eee; }

      .user-list-item__name {
        display: flex;
        flex-grow: 1; }

      .user-list-item__balance {
        display: flex; } } } }

.user-overview {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    background-color: $lux;
    color: white;
    justify-content: center; }

  .content {
    display: flex;
    width: 500px;
    flex-direction: column;
    align-self: center; } }

