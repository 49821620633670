$lux: #1a2147;

.navigation {
  display: flex;
  background-color: $lux;
  box-shadow: 0 3px 10px #525252;

  .logo {
    font-size: 56px;
    color: white;
    padding: 16px;
    min-width: 250px; }

  a {
    display: flex;
    min-height: 20px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    text-decoration: none;

    &:hover {
      background-color: lighten($lux, 20); }

    &.active {
      border-bottom: 2px solid white; } } }

.admin-container {
  display: flex;
  flex-grow: 1;
  flex-basis: 0; }

.admin-menu {
  display: flex;
  flex-direction: column;
  background-color: lighten($lux, 20);
  min-width: 300px;
  overflow: auto;

  a {
    display: flex;
    flex-direction: column;
    padding: 8px;
    min-height: 48px;
    color: white;
    justify-content: center;
    text-decoration: none;

    &.active {
      border-left: 2px solid white; }

    &:hover {
      background-color: lighten($lux, 40); } }

  .admin-new {
    margin: 8px; } }

.admin-content {
  display: flex;
  flex-direction: column;
  margin: 32px;
  min-width: 450px;

  .admin-header {
    display: flex;
    justify-content: space-between; }

  .admin-label {
    display: flex;

    input {
      display: flex;
      flex-grow: 1; } }

  .admin-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;

    > button {
      padding: 8px; } }

  .admin-new {
    margin-top: 8px; }

  .admin-table-summary td {
    border-top: 1px solid black; } }

.admin-new {
  font-size: 20px;
  padding: 8px; }
